<template>
  <v-card width="100%">
    <base-header-information
      :title="headerTitle"
      :description="headerDescription"
      color="grey lighten-1"
    ></base-header-information>
    <v-container class="wrap" fluid>
      <v-data-table
        :disable-sort="$vuetify.breakpoint.smAndDown"
        class="elevation-0"
        :headers="headers"
        :items="scheduleFilter"
        :items-per-page.sync="options.itemsPerPage"
        hide-default-footer
      >
        <template v-slot:item.valid="{ item }">
          {{ item.valid | moment('DD/MM/YYYY') }}
        </template>
        <template v-slot:item.canvas="{ item }">
          {{ item.canvas | moment('MMMM/YYYY') }}
        </template>
        <template v-slot:item.value="{ item }">
          {{ item.value | currencyFromCents }}
        </template>
        <template v-slot:item.actions="{ item }" align="center">
          <v-btn icon @click="navigateToRouter(item)">
            <v-icon color="grey darken-2" medium>call_made</v-icon>
          </v-btn>
        </template>
        <template slot="no-data">
          <p class="text-center blue--text py-6 headline">
            <v-icon color="blue" medium>info</v-icon> Não há movimentos em sua
            agenda.
          </p>
        </template>
      </v-data-table>
    </v-container>
  </v-card>
</template>

<script>
import { mapState, mapActions } from 'vuex'

export default {
  computed: {
    ...mapState({
      schedule: (state) => state.schedule.schedule,
    }),
    scheduleFilter() {
      return this.schedule.filter((item) => this.filterListByDate(item))
    },

    addClassMobol() {
      if (this.$vuetify.breakpoint.smAndDown) {
        this.classMobol.push('text-truncate')
        return this.classMobol
      }
      return (this.classMobol = [])
    },
    addStyleMobol() {
      if (this.$vuetify.breakpoint.smAndDown) {
        this.styleMobol.push({ width: '5rem' })
        return this.styleMobol
      }
      return (this.styleMobol = [])
    },
    headerTitle() {
      return `Obrigações de ${this.$moment().format('MMMM')}`
    },
  },
  data: () => ({
    headers: [
      {
        text: 'Prazo',
        align: 'left',
        value: 'valid',
      },
      {
        text: 'Período de Apuração',
        value: 'canvas',
      },
      {
        text: 'Categoria',
        value: 'categoryLabel',
      },
      {
        text: 'Nome',
        value: 'detail',
      },
      {
        text: 'Valor',
        value: 'value',
        class: ['hidden-sm-and-down'],
      },
      {
        text: 'Status',
        value: 'state',
        class: ['hidden-sm-and-down'],
      },
      {
        text: '',
        value: 'actions',
      },
    ],
    options: {
      itemsPerPage: -1,
    },
    classMobol: [],
    styleMobol: [],
    headerDescription: `Simplifique o seu dia a dia! Nesta área, você visualiza o resumo das atividades contábeis do mês da sua empresa.
    É importante ficar sempre atento aos status e pendências para não se esquecer de nada!`,
    url: null,
    filterDate: {
      isActive: true,
      date: new Date(),
    },
  }),
  methods: {
    ...mapActions({
      scheduleGet: 'schedule/get',
      dialogsChangeNewExpense: 'dialogs/changeNewExpense',
    }),
    getColor(e) {
      switch (e.state.toLowerCase()) {
        case 'vencida':
          return 'red lighten-5'
        case 'pago':
          return 'green lighten-5'
        case 'pendente':
          return 'yellow lighten-5'
        default:
          return ''
      }
    },
    navigateToRouter(item) {
      switch (item.category) {
        case 'tax':
          this.url = 'taxation'
          break

        default:
          this.url = 'home'
          break
      }
      this.$router.push({ name: this.url })
    },
    isMobol() {
      return this.$vuetify.breakpoint.mdAndUp
    },
    filterListByDate(item) {
      return (
        this.$moment(this.filterDate.date).format('YYYY-MM') ==
        this.$moment(item.valid).format('YYYY-MM')
      )
    },
  },
  mounted() {
    this.$store.dispatch('schedule/get')
  },
}
</script>
<style scoped lang="sass"></style>
